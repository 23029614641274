import React, { useState, useEffect } from 'react';
import { Session } from '@supabase/supabase-js';
import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, Avatar, Box, IconButton } from '@mui/material'; // Changed Button to IconButton
import { Business } from '@mui/icons-material';
import { getBusinessList } from '../services/api';
import styles from './BusinessList.module.css';
import logo from '../assets/png/favicon-8.png';
import ProfileIndicator from './ProfileIndicator';
import MessageIcon from '@mui/icons-material/Message'; // Import the Message icon
import { Profile } from '../services/api';

interface BusinessInfo {
  id: string;
  name: string;
  phone: string;
  email: string;
  username: string;
}

interface BusinessListProps {
  session: Session;
  profile: Profile | null;
  onSignOut: () => void;
}

const BusinessList: React.FC<BusinessListProps> = ({ session, profile, onSignOut }) => {
  const [businesses, setBusinesses] = useState<BusinessInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(''); // Add state for search term

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        setLoading(true);
        const response = await getBusinessList();
        setBusinesses(response.data);
      } catch (error) {
        console.error('Error fetching business list', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBusinesses();
  }, []);

  if (loading) return <div>Fetching business list...</div>;

  return (
    <div className={styles.container}>
      <Box className={styles.header}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="hotlines.ai logo" className={styles.logo} />
          <Typography variant="h4" className={styles.title}>hotlines.ai</Typography>
        </div>
        <div className={styles.iconContainer}> {/* For the icons to be aligned at the right edge */}
          <IconButton component={Link} to="/chats" color="inherit">
            <MessageIcon />
          </IconButton>
          <ProfileIndicator session={session} profile={profile} onSignOut={onSignOut} />
        </div>
      </Box>
      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {/* TODO: Make Search actually work */}
          <input 
            type="text" 
            placeholder="Search businesses and organisations..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)} 
            className={styles.searchBar}
          />
          {businesses.map((business) => (
            <Card 
              key={business.id} 
              component={Link} 
              to={`/business/${business.username}`} 
              // TODO: Implement Business type and use it for differential styling
              className={`
                ${business.name.includes('AI') ? styles.aiCard : ''}
                ${business.name.includes('Partner') ? styles.partnerCard : ''}
                ${styles.card}
              `}
            >
              <CardContent className={styles.content}>
                <Avatar className={styles.avatar}>
                  <Business />
                </Avatar>
                <div className={styles.info}>
                  <Typography variant="h6" className={styles.name}>{business.name}</Typography>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusinessList;
