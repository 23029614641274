import React, { useEffect, useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import { getChats, Chat } from '../services/api';
import styles from './ChatList.module.css';
import { useNavigate } from 'react-router-dom';
import { Profile } from '../services/api';
import { Session } from '@supabase/supabase-js';

// Define the props interface
interface ChatListProps {
    profile: Profile | null;
    session: Session;
}

const ChatList: React.FC<ChatListProps> = ({ profile, session }) => {
    const [chats, setChats] = useState<Chat[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchChats = async () => {
            try {
                setLoading(true);
                const response = await getChats(session); // Use getChats to fetch chats
                setChats(response.data);
            } catch (error) {
                console.error('Error fetching chats:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchChats();
    }, [session]);

    if (loading) return <div>Loading...</div>;

    return (
        <Box className={styles.container}>
            <Button variant="outlined" onClick={() => navigate('/')} style={{ marginBottom: '16px' }}>
                Back to Feed
            </Button>
            <Typography variant="h4" className={styles.header}>Your Chats</Typography>
            <List>
                {chats.map(chat => (
                    <div key={chat.id}>
                        <ListItem className={styles.chatItem}>
                            <ListItemText
                                primary={<span className={styles.chatBusinessName}>{chat.business.username}</span>} //TODO: Update to use business name, and make different behavior for business user where this shows user
                                secondary={<span className={styles.chatLastMessage}>{chat.lastMessage}</span>}
                            />
                        </ListItem>
                        <div className={styles.separator} /> {/* Separator between chat items */}
                    </div>
                ))}
            </List>
        </Box>
    );
};

export default ChatList;