import React from 'react';
import { Avatar, Box } from '@mui/material';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../lib/supabaseClient';
import { Profile } from '../services/api';

interface ProfileIndicatorProps {
  session: Session;
  profile: Profile | null; 
  onSignOut: () => void;
}

const ProfileIndicator: React.FC<ProfileIndicatorProps> = ({ session, profile, onSignOut }) => {
  if (!session) return null;

  // Determine initials
  let initials = '';
  if (profile && profile.first_name && profile.last_name) {
    initials = `${profile.first_name.charAt(0).toUpperCase()}${profile.last_name.charAt(0).toUpperCase()}`;
  } else if (session.user.email) {
    initials = session.user.email.charAt(0).toUpperCase(); // Use first letter of email if profile is null
  }

  const handleSignOut = async () => {
    await supabase.auth.signOut(); // Sign out the user
    onSignOut(); // Call the sign out handler passed from parent
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={handleSignOut}>
      <Avatar src={session.user.user_metadata.avatar_url} alt={session.user.email}>
        {initials}
      </Avatar>
    </Box>
  );
};

export default ProfileIndicator;
