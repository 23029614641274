import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Session } from '@supabase/supabase-js';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000/api'; // Fallback for local development

// Interfaces
export interface Business {
    id: string;
    username: string;
    name: string;
    phone: string;
    email: string;
    description?: string;
    website?: string;
    actions?: { [key: string]: string };
}

export interface Profile {
    account_id: string | null;
    created_at: string;
    first_name: string | null;
    last_name: string | null;
    profile_id: string;
    type: "customer" | "business_human" | "business_ai";
}

// TODO: revisit data model for chats/profiles for efficiency
export interface Chat {
    id: string;
    userId: string;
    businessId: string;
    business: {
        username: string;
    }
    businessName: string;
    lastMessage: string;
    lastMessageTimestamp: Date;
}

export interface Message {
    id: string;
    chatId: string;
    senderId: string;
    profiles: {
        type: string; // TODO: Make enum
    }
    content: {
        text: string;
    }
    timestamp: Date;
}

// API functions
export const getBusinessList = async (): Promise<{ data: Business[] }> => {
    const response = await axios.get(`${API_BASE_URL}/business`);
    return response['data'];
};

export const getBusinessInfo = async (username: string): Promise<{ data: Business }> => {
    const response = await axios.get(`${API_BASE_URL}/business/${username}`);
    return response['data'];
};

export const getChats = async (session: Session): Promise<{ data: Chat[] }> => {
    const response = await axios.get(`${API_BASE_URL}/chats`, {
        headers: {
            Authorization: `Bearer ${session.access_token}`,
        },
    });
    return response.data;
};

export const getChatMessages = async (chatId: string, session: Session): Promise<{ data: Message[] }> => {
    const response = await axios.get(`${API_BASE_URL}/chats/${chatId}/messages`, {
        headers: {
            Authorization: `Bearer ${session.access_token}`,
        },
    });
    return response.data;
};

// Updated sendMessage function
export const sendMessage = async (chatId: string, content: string, session: Session): Promise<{ data: Message }> => {
    const newMessage: Message = {
        id: uuidv4(), // You can still generate a UUID for local use, but the backend should handle the ID generation if needed
        chatId,
        senderId: session.user.id, // Use the user ID from the session
        profiles: { type: 'customer' },
        content: { text: content },
        timestamp: new Date(),
    };

    const response = await axios.post(`${API_BASE_URL}/chats/${chatId}/messages`, newMessage, {
        headers: {
            Authorization: `Bearer ${session.access_token}`,
        },
    });

    return response.data; // Return the response from the backend
};

export const getProfileInfo = async (session: Session): Promise<{ data: Profile }> => {
    // Make an API call to fetch profile info
    const response = await axios.get(`${API_BASE_URL}/profile`, {
        headers: {
            Authorization: `Bearer ${session.access_token}`, // Include the session token
        },
    });
    return response.data;
};

export const processDocument = async (file: File, parameters: any): Promise<any> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('parameters', JSON.stringify(parameters));

    const response = await axios.post(`${API_BASE_URL}/documents/extractDoc`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};
