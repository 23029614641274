import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { getChatMessages, sendMessage, Chat, Message } from '../services/api';
import styles from './ChatContainer.module.css';
import { Session } from '@supabase/supabase-js';

interface ChatContainerProps {
  chat: Chat | null;
  session: Session;
  businessUsername: string;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ chat, session, businessUsername }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const fetchMessages = useCallback(async () => {
    if (!chat) return;
    try {
      setLoading(true);
      const response = await getChatMessages(chat.id, session);
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  }, [chat, session]);

  useEffect(() => {
    if (chat) {
      fetchMessages();
    } else {
      setLoading(false);
      setMessages([]);
    }
  }, [chat, fetchMessages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      try {
        if (chat) {
          await sendMessage(chat.id, newMessage, session);
          setNewMessage('');
          await fetchMessages();
        } else {
          // TODO: Implement creating a new chat and sending the first message
          console.log('Creating new chat and sending message:', newMessage);
          setNewMessage('');
        }
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  return (
    <Box className={styles.container}>
      {loading ? (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      ) : (
        <Box className={styles.messagesContainer}>
          {messages.length > 0 ? (
            messages.map((message) => (
              <Box
                key={message.id}
                className={`${styles.message} ${message.content.text.includes('AI') ? styles.aiMessage : message.profiles.type === 'customer' ? styles.userMessage : styles.businessMessage}`}
              >
                <Typography variant="body2" className={styles.messageContent}>
                  {message.content.text}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography variant="body2" className={styles.noMessages}>
              {chat ? 'No messages yet. Start the conversation!' : `Start a conversation with ${businessUsername}!`}
            </Typography>
          )}
          <div ref={messagesEndRef} />
        </Box>
      )}
      <Box className={styles.inputContainer}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
          className={styles.input}
        />
        <Button variant="contained" onClick={handleSendMessage} className={styles.sendButton}>
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default ChatContainer;