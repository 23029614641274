import React, { useState } from 'react';
import { Button, TextField, Box } from '@mui/material';
import { processDocument } from '../services/api'; // Import the new function

// For now the only workflow type is form submission, but can extend in future.

const WorkflowContainer: React.FC<{ session: any }> = ({ session }) => {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const processDocumentHandler = async () => {
    if (!file) return;

    try {
        const response = await processDocument(file, {});

        // Clean the response by removing unwanted markers
        const cleanedResponse = response.replace(/```json|```/g, '').trim();

        // Parse the cleaned response
        const parsedResponse = JSON.parse(cleanedResponse);

        // Set the name and address from the parsed response
        setName(parsedResponse.name);
        setAddress(parsedResponse.address);
    } catch (error) {
        console.error('Error processing document:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <h2>Form submission workflow</h2>
      <input type="file" onChange={handleFileChange} />
      {file && (
        <Box sx={{ marginTop: 2 }}>
          <h3>File Preview:</h3>
          {file.type.startsWith('image/') ? (
            <img src={URL.createObjectURL(file)} alt="File Preview" style={{ maxWidth: '100%', maxHeight: '300px' }} />
          ) : (
            <p>{file.name}</p> // Display file name for non-image files
          )}
        </Box>
      )}
      <Button variant="contained" onClick={processDocumentHandler} disabled={!file}>
        Process Document
      </Button>
      <Box sx={{ marginTop: 2 }}>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          fullWidth
          margin="normal"
        />
      </Box>
      <Button variant="contained" onClick={() => console.log('Submit to Business clicked!')} sx={{ marginTop: 2 }}>
        Submit to Business
      </Button>
    </Box>
  );
};

export default WorkflowContainer;
