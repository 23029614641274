import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  IconButton,
  Paper,
  CircularProgress,
} from '@mui/material';
import {
  ArrowBack as ArrowBackIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { getBusinessInfo, getChats, Business, Chat } from '../services/api';
import ChatContainer from './ChatContainer';
import styles from './BusinessProfile.module.css';
import { Session } from '@supabase/supabase-js';
import { Profile } from '../services/api';

interface BusinessProfileProps {
  session: Session;
  profile: Profile | null;
}

const BusinessProfile: React.FC<BusinessProfileProps> = ({ session }) => {
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  const [businessInfo, setBusinessInfo] = useState<Business | null>(null);
  const [chat, setChat] = useState<Chat | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [businessResponse, chatsResponse] = await Promise.all([
          getBusinessInfo(username!),
          getChats(session)
        ]);
        setBusinessInfo(businessResponse.data);
        const businessChat = chatsResponse.data.find(c => c.business.username === username);
        setChat(businessChat || null);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [username, session]); // Include session.user.id in the dependency array

  const handleBack = () => {
    navigate('/');
  };

  const handleCall = () => {
    if (businessInfo) {
      window.location.href = `tel:${businessInfo.phone}`;
    }
  };

  if (loading) {
    return (
      <Box className={styles.loadingContainer}>
        <CircularProgress />
      </Box>
    );
  }

  if (!businessInfo) {
    return <Typography>Business not found</Typography>;
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" className={styles.title}>
          {businessInfo.name}
        </Typography>
        <IconButton onClick={handleCall}>
          <PhoneIcon />
        </IconButton>
      </Box>

      <Paper elevation={1} className={styles.infoBox}>
        <Typography variant="body1" className={styles.description}>
          {businessInfo.description || 'No description available.'}
        </Typography>
        <Box className={styles.actionButtons}>
          <Button className={styles.actionButton}>Website</Button>
          <Button className={styles.actionButton}>Photos</Button>
          <Button className={styles.actionButton}>Updates</Button>
          <Button className={styles.actionButton}>Menu</Button>
        </Box>
      </Paper>

      <Typography variant="h6" className={styles.commonActionsTitle}>
        Common Actions
      </Typography>
      <Box className={styles.commonActions}>
        {/* Dynamically render action buttons from businessInfo.actions */}
        {businessInfo.actions && Object.entries(businessInfo.actions).map(([label, url]) => (
          <Button
            key={label}
            variant="outlined"
            className={styles.fullWidthButton}
            onClick={() => window.open(url, '_blank')}
          >
            {label}
          </Button>
        ))}
      </Box>

      <Paper elevation={3} className={styles.chatPaper}>
        <ChatContainer 
          chat={chat} 
          session={session} 
          businessUsername={username!}
        />
      </Paper>
    </Box>
  );
};

export default BusinessProfile;
